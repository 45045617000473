const getAdditionalStakeholders = (state) => state.additional;

const getSurveys = (state) => ({
  surveyIds: state.allSurveyIds,
  surveyData: state.surveysById,
  surveysTotal: state.totalCount,
});

const getStakeholders = (state) => state.stakeholders;

// TODO: Memoize this (reselect?). The sort() is used to allow for consistent comparison
const getNetwork = (state) =>
  state.allIds
    .filter((id) => id !== state.you)
    .sort()
    .map((id) => state.byId[id]);

/**
 * Get latest network info from previous surveys
 * (Only newest data by name)
 * @param {*} state
 * @returns
 */
const getPreviousNetwork = (state) => state.retake.previousNetwork;

const getPerson = (state, id) => state.byId[id];

const getYou = (state) => getPerson(state, state.you) || { id: state.you };

const getAPIRequest = (state) => ({
  you: getYou(state),
  network: getNetwork(state),
  stakeholders: getStakeholders(state),
  additionalStakeholders: getAdditionalStakeholders(state),
});

const getNodes = (state) => state.allIds.map((id) => ({ id, name: state.byId[id].name }));

const getLinks = (state) =>
  state.allIds
    .reduce(
      (i, j, k) =>
        i.concat(
          state.byId[j].network.map((l) => {
            if (!state.byId[l]) return null;

            return {
              source: k,
              target: state.allIds.indexOf(l),
              relationship: state.byId[l].relationship,
            };
          })
        ),
      []
    )
    .filter(Boolean)
    .reduce((acc, current) => {
      if (!acc.find((n) => n.target === current.source && n.source === current.target)) {
        acc.push(current);
      }
      return acc;
    }, []);

const getItemsForGraphBySurvey = (state, surveyId) => {
  if (state.surveysById && state.surveysById[surveyId]) {
    const { survey } = state.surveysById[surveyId];
    const allIds = [survey.you.id, ...survey.you.network];
    const byId = {
      ...survey.network.reduce(
        (accum, aNetwork) => Object.assign(accum, { [aNetwork.id]: aNetwork }),
        {}
      ),
      [survey.you.id]: survey.you,
    };
    const customState = { allIds, byId };
    return {
      links: getLinks(customState),
      nodes: getNodes(customState),
      you: survey.you.id,
      activeSurveyId: surveyId,
      youIndex: getNodes(customState).findIndex((n) => n.id === survey.you.id),
    };
  }
  return null;
};

const getIndividualResources = (state = {}) => {
  const networkById = { ...state.byId };
  delete networkById[state.you];
  return Object.keys(networkById).map((connectionId) => ({
    name: state.byId[connectionId].name,
    work: state.byId[connectionId].work,
    motivators: state.byId[connectionId].motivators,
    support: state.byId[connectionId].support,
    friend: state.byId[connectionId].friend,
    carrer: state.byId[connectionId].career,
    innovators: state.byId[connectionId].innovators,
    growth: state.byId[connectionId].growth,
    mentoring: state.byId[connectionId].mentoring,
  }));
};

const getIndividualResourcesBySurvey = (state, surveyId) => {
  if (state.surveysById && state.surveysById[surveyId]) {
    const { survey } = state.surveysById[surveyId];
    const byId = {
      ...survey.network.reduce(
        (accum, aNetwork) => Object.assign(accum, { [aNetwork.id]: aNetwork }),
        {}
      ),
      [survey.you.id]: survey.you,
    };
    const you = survey.you.id;
    const customState = { byId, you };
    return getIndividualResources(customState);
  }
  return null;
};
const getCohortStatistics = (state) => state.cohortStatistics;

const getActiveSurveyID = (state) => state.activeSurveyID;

const getResources = (state) => state.results.resources;

const getCohortCode = (state) => state.cohortCode;

const getReportData = (state) => state.results.rawResult;

const getCohortsList = (state) => state.cohorts.cohortsList;

const getSelectedCohort = (state) => state.cohorts.selectedCohort;

const getSurveyCohort = (state) => state.cohorts && state.cohorts.surveyCohort;

const getUser = (state) => state.user;

const getIsSavingSurvey = (state) => state.isSavingSurvey;

const getCohortCount = (state) => state.cohorts.cohortCount;
const getCohortsCurrentPage = (state) => state.cohorts.currentPage;
const getCohortsOrder = (state) => state.cohorts.order;
const getCohortsSearchTerm = (state) => state.cohorts.searchTerm;
const getIsFetchingCohorts = (state) => state.cohorts.isFetching;
const getIsIsCohortsListOutdated = (state) => state.cohorts.isCohortsListOutdated;
const getIsCohortsModalOpen = (state) => state.cohorts.isModalOpen;
const getIsUpdateCohortModal = (state) => state.cohorts.isUpdateModal;
const getCohortsError = (state) => state.cohorts.error;

const getOrganizationsList = (state) => state.organizations.organizationsList;
const getOrganizationCount = (state) => state.organizations.organizationsCount;
const getOrganizationsError = (state) => state.organizations.error;
const getOrganizationsCurrentPage = (state) => state.organizations.currentPage;
const getOrganizationsOrder = (state) => state.organizations.order;
const getOrganizationsSearchTerm = (state) => state.organizations.searchTerm;
const getIsFetchingOrganizations = (state) => state.organizations.isFetching;
const getIsOrganizationsListOutdated = (state) =>
  state.organizations.isOrganizationsListOutdated;
const getIsOrganizationsModalOpen = (state) => state.organizations.isModalOpen;
const getSelectedOrganization = (state) => state.organizations.selectedOrganization;
const getIsUpdateOrganizationModal = (state) => state.organizations.isUpdateModal;

const getIsContactsModalOpen = (state) => state.contacts.isModalOpen;
const getContactsList = (state) => state.contacts.contactsList;
const getContactsCount = (state) => state.contacts.contactsCount;
const getContactsError = (state) => state.contacts.error;
const getContactsIsFetching = (state) => state.contacts.isFetching;
const getIsContactsListIsOutdated = (state) => state.contacts.isContactsListOutdated;
const getContactsOrder = (state) => state.contacts.order;
const getContactsSearchTerm = (state) => state.contacts.searchTerm;
const getSurveyTakerId = (state) => {
  const you = getYou(state);

  if (!you.email) return you.id;

  const contactsList = getContactsList(state);

  if (!contactsList) return you.id;

  const contact = contactsList.find((c) => c.email === you.email);

  if (contact && contact.id) return contact.id;

  return you.id;
};

const getGoals = (state) => state.goals;

const getPreviousSurveyId = (state) => state.retake?.previousSurveyId;

const getResubmission = (state) => state.retake.resubmission;

const getUserGoals = (state) => state.userGoals;

const getRetakeAnswers = (state) => state.retakeAnswers;

const getImmediateReport = (state) => state.cohorts?.surveyCohort?.options.immediate_flow;

const getIsRetake = (state) => {
  if (state.retake?.previousSurveyId) {
    return state.cohorts?.surveyCohort?.options?.includes_retake;
  }
  return false;
};

const getConsent = (state) => state.consent;

const getNetworkIds = (state) => state.allIds;

export {
  getConsent,
  getSurveyCohort,
  getActiveSurveyID,
  getAdditionalStakeholders,
  getAPIRequest,
  getLinks,
  getNetwork,
  getPreviousNetwork,
  getNodes,
  getPerson,
  getResources,
  getStakeholders,
  getYou,
  getCohortCode,
  getIndividualResources,
  getReportData,
  getSurveys,
  getItemsForGraphBySurvey,
  getIndividualResourcesBySurvey,
  getCohortStatistics,
  getCohortsList,
  getSelectedCohort,
  getUser,
  getIsSavingSurvey,
  getCohortCount,
  getCohortsCurrentPage,
  getCohortsOrder,
  getCohortsSearchTerm,
  getIsFetchingCohorts,
  getIsIsCohortsListOutdated,
  getIsCohortsModalOpen,
  getIsUpdateCohortModal,
  getCohortsError,
  getOrganizationsList,
  getOrganizationCount,
  getOrganizationsError,
  getOrganizationsCurrentPage,
  getOrganizationsOrder,
  getOrganizationsSearchTerm,
  getIsFetchingOrganizations,
  getIsOrganizationsListOutdated,
  getIsOrganizationsModalOpen,
  getSelectedOrganization,
  getIsUpdateOrganizationModal,
  getIsContactsModalOpen,
  getContactsList,
  getContactsCount,
  getContactsError,
  getContactsIsFetching,
  getIsContactsListIsOutdated,
  getContactsOrder,
  getContactsSearchTerm,
  getSurveyTakerId,
  getGoals,
  getPreviousSurveyId,
  getUserGoals,
  getRetakeAnswers,
  getImmediateReport,
  getResubmission,
  getIsRetake,
  getNetworkIds,
};
