import { gql } from '@apollo/client';
import { useApolloClient } from '@apollo/react-hooks';
import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setCohortsList, setCohortCount, setCohortsError } from 'lib/redux/actions';
import {
  getCohortsCurrentPage,
  getCohortsOrder,
  getCohortsSearchTerm,
  getIsIsCohortsListOutdated,
} from 'lib/redux/selectors';
import { ROLES } from 'lib/utils/constants';

const PER_PAGE = 10;

const GET_COHORTS = gql`
  query cohortsList(
    $skip: Int
    $limit: Int
    $orderField: String
    $secondaryOrderField: String
    $orderDirection: Int
    $searchTerm: String
    $organizationId: String
  ) {
    cohorts(
      skip: $skip
      limit: $limit
      orderField: $orderField
      secondaryOrderField: $secondaryOrderField
      orderDirection: $orderDirection
      searchTerm: $searchTerm
      organizationId: $organizationId
    ) {
      cohorts {
        id
        name
        trainers
        created
        organization_id
        contacts
        options {
          immediate_flow
          individual_purchase
          price
          expires_at
          max_participants
          includes_retake
          demographicQuestions
          contactsLimited
          enableAutocomplete
          enableTrainersViewReport
          goalsReminderSequence
          preRetakeSequence
          retakeDate
          preRetakeDate
          retakeReminderSequence
          retakeSequence
          startDate
          startReminderSequence
          startSequence
          incompleteSequence
          editWindow
        }
        surveysCount
      }
      totalCount
    }
  }
`;

const GET_TRAINER_COHORTS = gql`
  query trainerCohortsList(
    $email: String!
    $skip: Int
    $limit: Int
    $orderField: String
    $secondaryOrderField: String
    $orderDirection: Int
    $searchTerm: String
  ) {
    cohorts: trainerCohorts(
      email: $email
      skip: $skip
      limit: $limit
      orderField: $orderField
      secondaryOrderField: $secondaryOrderField
      orderDirection: $orderDirection
      searchTerm: $searchTerm
    ) {
      cohorts {
        id
        name
        trainers
        created
        contacts
        options {
          immediate_flow
          individual_purchase
          price
          expires_at
          max_participants
          includes_retake
          contactsLimited
          enableTrainersViewReport
          demographicQuestions
          goalsReminderSequence
          preRetakeSequence
          retakeDate
          preRetakeDate
          retakeReminderSequence
          retakeSequence
          startDate
          startReminderSequence
          startSequence
          incompleteSequence
        }
        surveysCount
      }
      totalCount
    }
  }
`;

export default function useCohortsList(role = '', email = '', selectedOrganization) {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const currentPage = useSelector(getCohortsCurrentPage);
  const order = useSelector(getCohortsOrder);
  const searchTerm = useSelector(getCohortsSearchTerm);
  const isOutdated = useSelector(getIsIsCohortsListOutdated);
  const isTrainer = role === ROLES.TRAINER;
  const isAdmin = role === ROLES.ADMIN;
  const isOrgRep = role === ROLES.ORG_REP;

  const hasValidRole = () =>
    isAdmin ||
    isTrainer ||
    (isOrgRep && selectedOrganization && Object.keys(selectedOrganization).length > 0);

  useEffect(() => {
    const getCohorts = async () => {
      try {
        if (!hasValidRole()) return;

        const variables = {
          skip: (currentPage - 1) * PER_PAGE,
          limit: PER_PAGE,
          orderField: order.field,
          secondaryOrderField: order.secondaryField,
          orderDirection: order.direction,
          searchTerm,
          organizationId: (selectedOrganization && selectedOrganization.id) || null,
        };
        const {
          data: {
            cohorts: { cohorts, totalCount },
          },
        } = await client.query({
          query: isTrainer ? GET_TRAINER_COHORTS : GET_COHORTS,
          variables: isTrainer || isOrgRep ? { ...variables, email } : variables,
          fetchPolicy: 'no-cache',
        });

        dispatch(setCohortCount(totalCount));
        dispatch(setCohortsList(cohorts));
      } catch (err) {
        dispatch(setCohortsError(true, 'An error occurred while fetching the cohorts.'));
        Sentry.captureException(err);
      }
    };
    getCohorts();
  }, [role, isOutdated, selectedOrganization]);
}
