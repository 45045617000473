import React from 'react';
import NWLogo from 'public/assets/NL-logo-graphic-only.svg';

export default function NWLSpinner({
  smallView,
  coverView,
  fillView,
  actionText,
}) {
  return (
    <>
      <div
        style={{
          position: coverView && 'fixed',
          zIndex: '1000',
          color: coverView && 'orange',
          top: coverView && '50%',
          right: coverView && '50%',
          height: fillView && 'calc(100vh - 10rem)',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        className="NWLSpinner"
      >
        <img
          className="NWLSpinner_icon"
          style={{
            width: smallView ? '1.5rem' : '2.5rem',
            marginRight: smallView ? '0' : '0.5rem',
          }}
          src={NWLogo.src}
        />
        {actionText && `${actionText}...`}
      </div>
      <div
        style={{
          position: 'fixed',
          zIndex: coverView && '100',
          top: coverView && '0',
          left: coverView && '0',
          height: fillView && '100%',
          width: fillView && '100%',
          backgroundColor: 'rgba(255,255,255,0.7)',
          zIndex: 2,
        }}
      />
    </>
  );
}
