import { gql } from '@apollo/client';
import { useMutation } from '@apollo/react-hooks';
import withSessionHandler from 'lib/utils/withSessionHandler';

const SAVE_RETAKE_ANSWER = gql`
  mutation addRetakeAnswer(
    $retakeAnswers: RetakeAnswerInput
    $user: UserInput
    $cohort_id: ID
    $survey_id: ID
  ) {
    addRetakeAnswer(
      retakeAnswers: $retakeAnswers
      user: $user
      cohort_id: $cohort_id
      survey_id: $survey_id
    )
  }
`;

const useSaveRetakeAnswers = () => {
  const [saveRetakeAnswer] = withSessionHandler(useMutation(SAVE_RETAKE_ANSWER));
  return {
    saveRetakeAnswer,
  };
};

export default useSaveRetakeAnswers;
