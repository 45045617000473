module.exports = {
  surveysCollection: 'surveys',
  goalsCollection: 'goals',
  consentCollection: 'consent',
  retakeAnswersCollection: 'retakeAnswers',
  cohortsCollection: 'cohorts',
  organizationsCollection: 'organizations',
  contactsCollection: 'contacts',
  resultsCollection: 'results',
  emailsCollection: 'emails',
  deafultPurchaseAmount: 5500,
  contactUsTrainerLink:
    'https://forms.monday.com/forms/e9847777907f164c0cbdf575f43fa7bb?r=use1',
  contactUsIndividualLink:
    'https://forms.monday.com/forms/590417146380b957bf0dd4179de7fb8f?r=use1',
  lndIndividualCourseLink: 'https://buy.stripe.com/6oE2aDgxkbojapW5km',
  lndCertifiedTrainerCourseLink: 'https://buy.stripe.com/5kAaH94OCdwr7dK5kt',
  lndOrange: '#f59120',
  lndBlue: '#0e75bb',
  lndGreen: '#2bb573',
  lndPurple: '#652f8e',
  lndOrangeRGBOpaque: 'rgb(245, 145, 32, .5)',
  lndBlueRGBOpaque: 'rgb(27, 117, 186, .5)',
  lndGreenRGBOpaque: 'rgb(39, 179, 115, .5)',
  lndPurpleRGBOpaque: 'rgb(101, 47, 142, .5)',
  lndOrangeRGB: 'rgb(245, 145, 32)',
  lndBlueRGB: 'rgb(27, 117, 186)',
  lndGreenRGB: 'rgb(39, 179, 115)',
  lndPurpleRGB: 'rgb(101, 47, 142)',
  DAYS_WINDOW: Number(process.env.DAYS_WINDOW) || 30,

  MUCH_OLDER: 'much-older',
  OLDER: 'older',
  YOUNGER: 'younger',
  MUCH_YOUNGER: 'much-younger',
  SAME: 'same',
  NONE: 'none',
  SIMILAR: 'similar',
  DIFFERENT: 'different',
  UNKNOWN: 'unknwon',
  NA: 'na',

  // Survey's version
  DEFAULT: 'default',
  APRIL_2023: 'APRIL_2023',
  APRIL_2024: 'APRIL_2024',
};
