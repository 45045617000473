import React from 'react';
import Head from 'next/head';
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import fetch from 'isomorphic-unfetch';
import App from 'next/app';
import { Provider as StoreProvider } from 'react-redux';
import { UserProvider } from '@auth0/nextjs-auth0/client';
import BrowserNotSupported from 'components/BrowserNotSupported';
import Layout from 'components/Layout';
import initStore from 'lib/redux/initStore';
import { withRouter } from 'next/router';
import ToastProvider from 'components/Toast';

import 'lib/css/admin.scss';
import 'lib/css/cohortStatistics.scss';
import 'lib/css/common.scss';
import 'lib/css/contacts.scss';
import 'lib/css/depth.scss';
import 'lib/css/diversity.scss';
import 'lib/css/innerResources.scss';
import 'lib/css/landings.scss';
import 'lib/css/Layout.scss';
import 'lib/css/resources.scss';
import 'lib/css/survey.scss';
import 'lib/css/surveyLanding.scss';

const hasUniqueIEProp =
  typeof window !== 'undefined' && window.document && window.document.documentMode;
const ua = (typeof navigator !== 'undefined' && navigator.userAgent) || '';
const hasIEUA = /MSIE|Trident/.test(ua);
const isIE = hasUniqueIEProp || hasIEUA;

function createGraphQLClient(router) {
  const cache = new InMemoryCache();
  // https://github.com/apollographql/apollo-link/blob/master/packages/apollo-link-context/src/index.ts#L21
  const authLink = setContext((request, context) =>
    // We only have to return updated values
    // This returned object will be merged into the existing context
    // https://github.com/apollographql/apollo-link/blob/master/packages/apollo-link/src/linkUtils.ts#L106
    ({
      headers: {
        ...(context.headers || {}),
      },
    })
  );

  const resetToken = onError((error) => {
    const { networkError } = error;
    if (
      networkError &&
      networkError.statusCode === 401 &&
      networkError.bodyText === 'invalid token in Authorization header'
    ) {
      router.push(`/api/auth/logout`);
    }
  });

  const authWithTokenValidation = authLink.concat(resetToken);

  const httpLink = new HttpLink({
    uri: '/api/graphql',
    fetch,
    // credentials: 'same-origin', // send cookies back to origin too
  });

  const client = new ApolloClient({
    cache,
    link: authWithTokenValidation.concat(httpLink),
  });

  return client;
}

export default withRouter(
  class MyApp extends App {
    constructor(props) {
      super(props);
      this.auth = false;
      this.store = initStore();
      this.graphQLClient = createGraphQLClient(this.props.router);
    }

    render() {
      const { Component, err, ...passThroughProps } = this.props;

      if (isIE) {
        return (
          <>
            <Head>
              <title>Network Leader | Error</title>
            </Head>
            <BrowserNotSupported />
          </>
        );
      }

      if (Component.isErrorPage) {
        return (
          <>
            <Head>
              <title>Network Leader | Error</title>
            </Head>
            <Component auth={this.auth} {...passThroughProps} err={err} />
          </>
        );
      }

      return (
        <>
          <Head>
            <title>Network Leader</title>
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1, minimum-scale=1,shrink-to-fit=no"
            />
          </Head>
          <UserProvider>
            <ApolloProvider client={this.graphQLClient}>
              <StoreProvider store={this.store}>
                <ToastProvider>
                  <Layout auth={this.auth} showNavBar={!Component.hideNavBar}>
                    <Component auth={this.auth} {...passThroughProps} err={err} />
                  </Layout>
                </ToastProvider>
              </StoreProvider>
            </ApolloProvider>
          </UserProvider>
        </>
      );
    }
  }
);
